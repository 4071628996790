<template>
  <div class="content_body PostponeApproval" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="24">
          <el-form :inline="true" size="small" @keyup.enter.native="handleSearch">
            <el-form-item label="单据号">
              <el-input v-model="PostponeBillID" placeholder="输入单据号" clearable @clear="handleSearch"></el-input>
            </el-form-item>
            <el-form-item label="客户">
              <el-input v-model="Name" placeholder="名字、手机号或客户编号" clearable @clear="handleSearch"></el-input>
            </el-form-item>
            <el-form-item label="申请时间">
              <el-date-picker @clear="handleSearch" @change="handleSearch" v-model="SearchData" :picker-options="pickerOptions" type="daterange" unlink-panels range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="状态">
              <el-select @change="handleSearch" @clear="handleSearch" v-model="isAllowSell" placeholder="请选择审批状态" clearable>
                <el-option label="待审批" :value="20"></el-option>
                <el-option label="已同意" :value="30"></el-option>
                <el-option label="已驳回" :value="40"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>

    <div class="martp_10">
      <el-table size="small" :data="tableData" tooltip-effect="light">
        <el-table-column prop="ID" label="单据号"></el-table-column>
        <el-table-column prop="CustomerName" label="客户信息">
          <template slot-scope="scope">
            <div>{{scope.row.CustomerName}}</div>
            <div>手机号：{{scope.row.PhoneNumber}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="ApprovalStatus" label="审批状态">
          <template slot-scope="scope">{{scope.row.ApprovalStatus == 10?"草稿":scope.row.ApprovalStatus == 20?"待审批" :scope.row.ApprovalStatus == 30?"已同意":scope.row.ApprovalStatus ==40 ?"已拒绝":'已撤销'}}</template>
        </el-table-column>
        <el-table-column prop="CreatedOn" label="申请时间"></el-table-column>
        <el-table-column prop="CreatedByName" label="申请人"></el-table-column>
        <el-table-column prop="PostponeRemark" label="备注信息" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" width="80px">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="showApplyDetailDialog(scope.row)" v-prevent-click>详情</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="text_right pad_15">
        <el-pagination background v-if="paginations.total > 0" @current-change="handleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
      </div>
    </div>

    <!-- 详情 -->
    <el-dialog :visible.sync="applyDetailVisible" width="1100px">
      <span slot="title">
        <span>延期详情 </span>
      </span>
      <div style="height:60vh;font-size:13px !important">
        <el-scrollbar class="el-scrollbar_height">
          <div style="margin-right:10px" class="marbm_10">
            <el-form label-width="100px" size="small" class="postponeApprove">
              <div class="tip" style="margin-top:0">延期信息</div>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="单据号:">{{ApplyDetail.ID}}</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="申请人:">{{ApplyDetail.CreatedByName}}</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="申请时间:">{{ApplyDetail.CreatedOn}}</el-form-item>
                </el-col>

              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="客户信息:">{{ApplyDetail.CustomerName}} ({{ApplyDetail.PhoneNumber}})</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="审批状态:">{{ApplyDetail.ApprovalStatus == 10?"草稿":ApplyDetail.ApprovalStatus == 20?"待审批" :ApplyDetail.ApprovalStatus == 30?"已同意":ApplyDetail.ApprovalStatus ==40 ?"已驳回":'已撤销'}}</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="备注信息:">{{ApplyDetail.PostponeRemark}}</el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="IsShow">
                <el-col :span="8">
                  <el-form-item label="审批人:">{{ApplyDetail.ApprovalByName}}</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="审批时间:">{{ApplyDetail.ApprovalOn}}</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="审批说明:">{{ApplyDetail.ApprovalRemark}}</el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <div class="tip font_13">延期明细</div>

          <div class="border_left border_right border_top">
            <el-row class="row_Detalheader border_bottom" v-if="ApplyDetail.SavingCard!=undefined && ApplyDetail.SavingCard.length >0">
              <el-col :span="10">储值卡</el-col>
              <el-col :span="7">原失效日期</el-col>
              <el-col :span="7">新失效日期</el-col>
            </el-row>
            <el-row class="border_bottom" v-for="item in ApplyDetail.SavingCard" :key="item.SavingCardAccountID+'save'">
              <el-col :span="24" class="pad_10 ">
                <el-col :span="24">
                  <el-col :span="10">
                    {{item.Name}}
                    <span v-if="item.Alias">({{item.Alias}})</span>
                  </el-col>
                  <el-col :span="7">{{item.ValidDayName}}</el-col>
                  <el-col :span="7">{{item.NewValidDayName}}</el-col>
                </el-col>
              </el-col>
            </el-row>

            <el-row class="row_Detalheader border_bottom" v-if="ApplyDetail.GeneralCard!=undefined && ApplyDetail.GeneralCard.length>0">
              <el-col :span="10">通用次卡</el-col>
              <el-col :span="7">原失效日期</el-col>
              <el-col :span="7">新失效日期</el-col>
            </el-row>
            <el-row class="border_bottom" v-for="item in ApplyDetail.GeneralCard" :key="item.GeneralCardAccountID+'general'">
              <el-col :span="24" class="pad_10 ">
                <el-col :span="24">
                  <el-col :span="10">
                    {{item.Name}}
                    <span v-if="item.Alias">({{item.Alias}})</span>
                  </el-col>
                  <el-col :span="7">{{item.ValidDayName}}</el-col>
                  <el-col :span="7">{{item.NewValidDayName}}</el-col>
                </el-col>
              </el-col>
            </el-row>

            <el-row class="row_Detalheader border_bottom" v-if="ApplyDetail.TimeCard!=undefined && ApplyDetail.TimeCard.length >0">
              <el-col :span="10">时效卡</el-col>
              <el-col :span="7">原失效日期</el-col>
              <el-col :span="7">新失效日期</el-col>
            </el-row>
            <el-row class="border_bottom" v-for="item in ApplyDetail.TimeCard" :key="item.TimeCardAccountID+'time'">
              <el-col :span="24" class="pad_10 ">
                <el-col :span="24">
                  <el-col :span="10">
                    {{item.Name}}
                    <span v-if="item.Alias">({{item.Alias}})</span>
                  </el-col>
                  <el-col :span="7">{{item.ValidDayName}}</el-col>
                  <el-col :span="7">{{item.NewValidDayName}}</el-col>
                </el-col>
              </el-col>
            </el-row>

            <el-row class="row_Detalheader border_bottom" v-if="ApplyDetail.PackageCard!= undefined && ApplyDetail.PackageCard.length>0">
              <el-col :span="10">套餐卡</el-col>
              <el-col :span="7">原失效日期</el-col>
              <el-col :span="7">新失效日期</el-col>
            </el-row>
            <el-row class="border_bottom" v-for="item in ApplyDetail.PackageCard" :key="item.PackageCardAccountID+'pack'">
              <el-col :span="24" class="pad_10 ">
                <el-col :span="24">
                  <el-col :span="10">
                    {{item.Name}}
                    <span v-if="item.Alias">({{item.Alias}})</span>
                  </el-col>
                  <el-col :span="7">{{item.ValidDayName}}</el-col>
                  <el-col :span="7">{{item.NewValidDayName}}</el-col>
                </el-col>
              </el-col>
            </el-row>
          </div>
        </el-scrollbar>
      </div>
      <span slot="footer" class="dialog-footer text_center">
        <el-button @click="applyDetailVisible =false" size="small">取消</el-button>
        <el-button v-if="IsApprove" type="primary" size="small" @click="withdrawApplyClick">审批</el-button>
      </span>
    </el-dialog>

    <!--  撤销审批 -->
    <el-dialog :visible.sync="approvalVisible" title="审批" width="35%">
      <div>
        <el-form label-width="100px" size="small">
          <el-form-item label="审核:" prop="Approve">
            <el-radio-group v-model="Approve">
              <el-radio :label="30">同意</el-radio>
              <el-radio :label="40">拒绝</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="审批意见:">
            <el-input type="textarea" v-model="ApprovalRemark" placeholder="请输入备注"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="approvalVisible=false" size="small">取 消</el-button>
        <el-button type="primary" @click="confrimWithdrawApplyClick" size="small">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/iBeauty/PostponeTransfer/PostponeApproval";
import date from "@/components/js/date";

export default {
  name: "PostponeApproval",
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      loading: false,
      IsAllowSell: true,
      ID: "",
      Name: "",
      SearchData: "",
      EndDate: "",
      StartDate: "",
      isAllowSell: "",
      ApplyDetail: "",
      PostponeBillID: "",
      ApprovalRemark: "",
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      tableData: [],
      IsShow: true,
      IsDraft: false,
      IsApprove: false,
      Approve: 30,
      applyDetailVisible: false,
      approvalVisible: false,

      currentDate: new Date(),
    };
  },

  methods: {
    handleCurrentChange(page) {
      this.paginations.page = page;
      this.PostponeBillApprovalList();
    },
    handleSearch() {
      var that = this;
      that.paginations.page = 1;
      that.PostponeBillApprovalList();
    },
    //延期审批列表
    PostponeBillApprovalList() {
      var that = this;
      if (that.SearchData == null || that.SearchData == "") {
        that.StartDate = "";
        that.EndDate = "";
      } else {
        that.StartDate = that.SearchData[0];
        that.EndDate = that.SearchData[1];
      }
      var params = {
        PageNum: that.paginations.page,
        PostponeBillID: that.PostponeBillID,
        Name: that.Name,
        StartDate: that.StartDate,
        EndDate: that.EndDate,
        ApprovalStatus: that.isAllowSell,
      };
      API.PostponeBillApprovalList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.tableData = res.List;
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          } else {
            that.$message.error({
              message: res.Messagem,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    //radio点击事件
    changeApprove(e) {
      this.ApproveApprovalStatus = e;
    },
    withdrawApplyClick() {
      var that = this;
      that.ApprovalRemark = "";
      that.Approve = 30;
      that.approvalVisible = true;
    },

    showApplyDetailDialog(row) {
      var that = this;
      that.loading = true;
      that.ID = row.ID;
      var params = {
        ID: that.ID,
      };
      API.PostponeBillDeatail(params).then((res) => {
        if (res.StateCode == 200) {
          that.ApplyDetail = res.Data;
          if (
            that.ApplyDetail.ApprovalStatus == 10 ||
            that.ApplyDetail.ApprovalStatus == 20 ||
            that.ApplyDetail.ApprovalStatus == 50
          ) {
            that.IsShow = false;
          } else {
            that.IsShow = true;
          }
          if (that.ApplyDetail.ApprovalStatus == 10) {
            that.IsDraft = true;
            that.IsApprove = false;
          } else if (that.ApplyDetail.ApprovalStatus == 20) {
            that.IsApprove = true;
          } else {
            that.IsApprove = false;
            that.IsDraft = false;
          }
          that.applyDetailVisible = true;
        }
      }).finally(function(){
        that.loading = false;
      });
    },

    confrimWithdrawApplyClick() {
      var that = this;
      var params = {
        ID: that.ID,
        ApprovalStatus: that.Approve,
        ApprovalRemark: that.ApprovalRemark,
      };
      API.ExtendPostponeBill(params).then((res) => {
        if (res.StateCode == 200) {
          that.$message.success({
            message: "提交成功",
            duration: 2000,
            customClass: "zZindex",
          });
          that.applyDetailVisible = false;
          that.approvalVisible = false;
          that.PostponeBillApprovalList();
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
            customClass: "zZindex",
          });
        }
      });
    },
  },
  mounted() {
    var that = this;
    // if (localStorage.getItem("access-user")) {
    //   that.userName = JSON.parse(
    //     localStorage.getItem("access-user")
    //   ).EmployeeName;
    //   that.entityName = JSON.parse(
    //       localStorage.getItem("access-user")
    //     ).EntityName;
    // }
    // var date = new Date(),
    //     y = date.getFullYear(),
    //     m = date.getMonth();
    //    that.SearchData = [new Date(y, m, 1), new Date()];
    that.PostponeBillApprovalList();
  },
};
</script>

<style  lang="scss">
.PostponeApproval {
  .project_content {
    font-size: 13px;
    height: 100%;
    .project_left {
      border-right: 1px solid #eee;
      color: #303133;
      .el-tabs {
        .el-tabs__header {
          margin: 0;
          .el-tabs__nav-scroll {
            padding-left: 15px;
          }
        }
        .el-tabs__content {
          height: calc(100% - 40px);
          .el-tab-pane {
            height: 100%;
            .col_border {
              border-right: 1px solid #eee;
              padding: 5px;
              color: #666;
              font-size: 14px;
            }
          }
        }
      }
    }
    .project_right {
      height: 100%;
      .el-icon-sort {
        color: #666;
        font-size: 20px;
        transform: rotate(90deg);
      }
      .el-main {
        padding: 0;
        .row_header {
          background-color: #ecf8ff;
          padding: 10px;
        }
        .employee_num {
          width: 90px;
          .el-input-group__append {
            padding: 0 10px;
          }
        }
        .back_project_col {
          background-color: #fafafa;
        }
      }
      .el-footer {
        height: initial !important;
        padding: 10px;
      }
      .itemWidth {
        width: 50%;
      }
    }
  }
  .row_Detalheader {
    background-color: #ecf8ff;
    padding: 10px;
  }
  .el-scrollbar_height {
    height: 100%;
    /deep/.el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }

  .dialog_bill_detail {
    background-color: #ecf8ff;
    padding: 15px;
    border-radius: 5px;
  }

  .postponeApprove {
    .el-form-item__label {
      font-size: 13px !important;
    }
    .el-form-item__content {
      font-size: 13px !important;
    }
    .el-form-item {
      margin-bottom: 0px;
    }
  }
}
</style>